<template>
  <div class="page-container full-content">
    <h1>Game Events</h1>
  </div>
</template>

<script>
import RequireAuthentication from '@/mixins/RequireAuthentication'

export default {
  mixins: [RequireAuthentication],
  name: 'GameEvents'
}
</script>

<style scoped>

</style>
