import { render, staticRenderFns } from "./AdminCreateEvent.vue?vue&type=template&id=dafe2514&scoped=true&"
import script from "./AdminCreateEvent.vue?vue&type=script&lang=js&"
export * from "./AdminCreateEvent.vue?vue&type=script&lang=js&"
import style0 from "./AdminCreateEvent.vue?vue&type=style&index=0&id=dafe2514&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dafe2514",
  null
  
)

export default component.exports